import React from "react";
import { PrimaryLogoSVG, PrimaryLogoDarkSVG, PrimaryLogoLightSVG, PrimaryIconSVG, PrimaryIconDarkSVG, PrimaryIconLightSVG, SecondaryLogoSVG, SecondaryLogoDarkSVG, SecondaryLogoLightSVG } from './../Assets/Images';
export const PrimaryLogo = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryLogoSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const PrimaryLogoDark = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryLogoDarkSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const PrimaryLogoLight = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryLogoLightSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const PrimaryIcon = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryIconSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const PrimaryIconDark = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryIconDarkSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const PrimaryIconLight = ({ Wsize, Hsize, Descripton }) => (
    <img src={PrimaryIconLightSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const SecondaryLogo = ({ Wsize, Hsize, Descripton }) => (
    <img src={SecondaryLogoSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const SecondaryLogoDark = ({ Wsize, Hsize, Descripton }) => (
    <img src={SecondaryLogoDarkSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
export const SecondaryLogoLight = ({ Wsize, Hsize, Descripton }) => (
    <img src={SecondaryLogoLightSVG} width={Wsize} height={Hsize} alt={Descripton || 'İşletmenizin Güvenli Erişim Ortağı'} />
)
