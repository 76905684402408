import React from "react";
import { Routes, Route } from "react-router-dom";
import Page from './Routes/ComingSoon'
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Page />} />
    </Routes>
  )
}
export default App;