import React from 'react';
import { Grid, Row, Col } from 'rsuite';
import { PrimaryLogoLight } from '../Utils'
const ComingSoon = () => {
    return (
        <Grid fluid className='coming-soon'>
            <Row>
                <Col lgOffset={7} xs={24} lg={10} className='text-align-center'>
                    <PrimaryLogoLight Hsize={80} />
                    <h1 className='m-30'> Merhaba WiiSpot Sevenler </h1>
                    <p className='text-align-justify'> Bir süreliğine burada olamayacağız çünkü Wiispot olarak, sizin için daha da iyisini hazırlamak için yoğun bir şekilde çalışıyoruz! Yepyeni özellikler, hızlı bağlantılar ve etkileyici bir deneyimle geri döneceğiz.</p>
                    <p className='m-30 text-align-justify'> Geliştirme aşamasında olduğumuz için sabrınız ve anlayışınız için teşekkür ederiz. Yenilikçi Wi-Fi çözümlerimizle dolu bu heyecan verici yolculuğa sizinle birlikte devam etmek için sabırsızlanıyoruz.</p>
                    <p className='text-align-justify'> Bizi takip etmeye devam edin. En kısa sürede görüşmek üzere.</p>
                    <p className='m-30 text-align-justify'> Wiispot Ekibi</p>
                    <a className='white' href='mailto:merhaba@wiispot.com' target='blank'> merhaba@wiispot.com</a>
                </Col>
            </Row>
        </Grid>
    )
}
export default ComingSoon;